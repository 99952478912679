<template>
    <div>
        <ayl-berad-nav :nav="nav"></ayl-berad-nav>
        <div class="content-box">
            <div class="content-main">
                <el-form :model="form" :rules="rules" ref="form" :inline="true" :label-position="$config.labelPosition"
                    :label-width="$config.labelWidth">
                    <div class="form-label-base">发放工资</div>
                    <el-form-item label="工资名称" prop="salaryName">
                        <el-input v-model="form.salaryName" placeholder="请输入" maxlength="25"></el-input>
                    </el-form-item>
                    <el-form-item label="工资总额" prop="salaryTotal">
                        <el-input v-model="form.salaryTotal" placeholder="请输入" maxlength="25"></el-input>
                    </el-form-item>
                    <el-form-item label="标段名称" prop="bidId">
                        <el-cascader v-model="form.bidId" placeholder="请选择" :options=common_getBidNameAndIdList @change="getDistinctBid"
                            :show-all-levels="false" filterable>
                        </el-cascader>
                    </el-form-item>
                    <el-form-item label="项目组：">
                        <span>{{ form.projectGroupId }}</span>
                    </el-form-item>
                    <el-form-item label="工资月份" prop="salaryMonth">
                        <el-date-picker v-model="form.salaryMonth" type="month" value-format="yyyyMM" placeholder="选择月">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="费用周期" prop="salaryDate">
                        <el-date-picker
                            v-model="form.salaryDate"
                            unlink-panels
                            type="daterange"
                            format="yyyy-MM-dd"
                            :default-time="['00:00:00', '23:59:59']"
                            value-format="timestamp"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="附件" style="width: 100%">
                        <ayl-upload-files v-model="form.attachmentUrls" :limit="1" :maxSize="10240" :accept="''"></ayl-upload-files>
                    </el-form-item>
                    <el-divider></el-divider>
                    <div class="form-label-base">员工工资发放</div>
                    <div>
                        <el-button type="success" class="btn-success-hw" @click="onAdd">新增</el-button>
                        <el-button class="btn" style="margin-left: 20px">发放工资条</el-button>
                        <el-button type="info" style="margin-left: 20px" @click="onDelete" plain>删除</el-button>
                    </div>
                    <!-- 工资表 -->
                    <el-table :data="allNewTableData" class="mt20px" style="width: 100%" @selection-change="wageSelectionChange">
                        <el-table-column type="selection" width="55" v-if="allTableData.length !== 0" fixed="left">
                        </el-table-column>
                        <el-table-column prop="name" label="姓名" width="80">
                        </el-table-column>
                        <el-table-column prop="idCardNumber" label="身份证" width="180">
                        </el-table-column>
                        <el-table-column prop="personTypeDes" label="岗位" width="120">
                        </el-table-column>
                        <el-table-column prop="dutyDays" label="出勤天数" width="100">
                        </el-table-column>
                        <el-table-column prop="wageStandard" label="标准工资" width="100">
                        </el-table-column>
                        <el-table-column label="平时加班" width="120">
                            <el-table-column prop="usualOvertimeHours" label="小时" width="60">
                            </el-table-column>
                            <el-table-column prop="usualOvertimeWage" label="工资" width="60">
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="休息日加班" width="140">
                            <el-table-column prop="weekendOvertimeHours" label="小时" width="70">
                            </el-table-column>
                            <el-table-column prop="weekendOvertimeWage" label="工资" width="70">
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="节假日加班" width="120">
                            <el-table-column prop="holidayOvertimeHours" label="小时" width="60">
                            </el-table-column>
                            <el-table-column prop="holidayOvertimeWage" label="工资" width="60">
                            </el-table-column>
                        </el-table-column>
                        <el-table-column prop="wageSummary" label="工资小计" width="100">
                        </el-table-column>
                        <el-table-column label="福利" width="250">
                            <el-table-column prop="welfareAllowance" label="岗位津贴" width="70">
                            </el-table-column>
                            <el-table-column prop="welfareSubsidy" label="补贴" width="60">
                            </el-table-column>
                            <el-table-column prop="welfareSummer" label="高温费" width="60">
                            </el-table-column>
                            <el-table-column prop="welfareSummary" label="小计" width="60">
                            </el-table-column>
                        </el-table-column>
                        <el-table-column prop="wageShoud" label="应发工资" width="120">
                        </el-table-column>
                        <el-table-column label="扣款" width="390">
                            <el-table-column prop="deductionSocialSecurity" label="社保" width="60">
                            </el-table-column>
                            <el-table-column prop="deductionCasualty" label="意外险" width="60">
                            </el-table-column>
                            <el-table-column prop="deductionEmployer" label="雇主险" width="60">
                            </el-table-column>
                            <el-table-column prop="deductionIncomeTax" label="个税" width="60">
                            </el-table-column>
                            <el-table-column prop="deductionHaf" label="住房公积金" width="90">
                            </el-table-column>
                            <el-table-column prop="deductionSummary" label="合计" width="60">
                            </el-table-column>
                        </el-table-column>
                        <el-table-column prop="wageReal" label="实发工资" width="120">
                        </el-table-column>
                        <el-table-column prop="salaryRemark" label="备注" width="120">
                        </el-table-column>
                        <el-table-column label="操作" width="120" fixed="right" >
                            <template slot-scope="scope" v-if="allTableData.length !== 0">
                                <el-button @click="onEdit(scope.row.personId)" type="text" size="small"
                                    style="color:$-color-primary-3">编辑工资明细</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form>
            </div>
            <div class="tac" style="margin-top: 40px">
                <el-button type="success" class="btn-success-hw" @click="onSubmit">提 交</el-button>
                <!-- <el-button class="btn" style="margin-left: 10px" @click="onSubmit('下一条')">保存并录入下一条</el-button> -->
                <el-button type="danger" class="btn-danger" style="margin-left: 10px" @click="onClose">关 闭</el-button>
            </div>
        </div>
        <el-dialog title="选择人员" :visible.sync="personnelTableDialog">
            <el-form :model="formTable" ref="form" :inline="true" label-position="left" style="display: inline;" class="search-box">
                <el-form-item label="人员类型" class="form">
                    <el-select
                    v-model="formTable.personTypes"
                    allow-create
                    default-first-option
                    @change="clickPersonType"
                    placeholder="请选择"
                    style="width: 180px">
                    <el-option
                    v-for="(item, idx) in personDataType"
                    :key="idx"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <el-table :data="newAllTableData" class="mt20px" style="width: 100%" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55">
                </el-table-column>
                <el-table-column prop="name" label="姓名">
                </el-table-column>
                <el-table-column prop="idCardNumber" label="身份证号码">
                </el-table-column>
                <el-table-column label="人员类型">
                    <template slot-scope="scope">
                        <span>{{ personType(scope.row.personType)}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="companyId" label="所属企业">
                </el-table-column>
            </el-table>
            <div class="mt10px mb20px">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :page-size="pageSize"
                    :current-page.sync="currentPage"
                    layout="total, prev, pager, next, jumper"
                    :total="totalNum"
                    style="float: right"
                    >
                </el-pagination>
            </div>
            
            <!-- <ayl-table :table="table" @selection-change="handleSelectionChange"></ayl-table> -->
            <div slot="footer" class="dialog-footer">
                <el-button @click="personnelTableDialog = false">取 消</el-button>
                <el-button type="primary" @click="onDetermine">确 定</el-button>
            </div>
        </el-dialog>
        <ayl-dialog 
        :visible.sync="isShowDialog" 
        :viewUrl="viewUrlForDialog" 
        v-model = "dataForDialog"
        :async="true"
        width = "80%"
        :showBottomBtn="false"></ayl-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            const vm = this
            return {
                nav: [{
                    name: "发放工资"
                }],
                submitLoading: false,
                common_getBidNameAndIdList: [],
                common_getDistinctBid: [],
                personnelTableDialog: false,
                //弹窗有关
                viewUrlForDialog: ``,
                isShowDialog: false,
                dataForDialog:null,
                personData: [],      //人员列表
                newAllTableData: [],
                personDataType: [],   //人员类型
                totalNum: 0,
                currentPage: 1,
                pageSize: 10,
                //勾选列表获取个人信息
                newTableData: [],
                allTableData: [],
                allNewTableData: [
                    {
                        name: null,
                        idCardNumber: null,
                        personTypeDes: null,
                        personType: null,
                        dutyDays: null,    //出勤天数
                        wageStandard: null,   //标准工资
                        usualOvertimeHours: null,    //平均加班小时
                        usualOvertimeWage: null,     //平均加班工资
                        weekendOvertimeHours: null,    //休息日加班小时
                        weekendOvertimeWage: null,     //休息日加班工资
                        holidayOvertimeHours: null,    //节假日加班小时
                        holidayOvertimeWage: null,     //节假日加班工资
                        wageSummary: null,             //工资小计
                        welfareAllowance: null,       //岗位津贴
                        welfareSubsidy: null,          //补贴
                        welfareSummer: null,        //高温费
                        welfareSummary: null,      //福利小计
                        wageShoud: null,            //应发工资
                        deductionSocialSecurity: null,    //社保
                        deductionCasualty: null,        //意外险
                        deductionEmployer: null,       //雇主险
                        deductionIncomeTax: null,      //个税
                        deductionHaf: null,            //住房公积金
                        deductionSummary: null,       //扣款合计
                        wageReal: null,                //实发工资
                        salaryRemark: null,           //备注
                    }
                ],
                form: {
                    bidId: null,
                    salaryName: null,      //工资名称
                    salaryTotal: null,     //工资总额
                    bidName: null,         //标段名称
                    salaryMonth: null,     //工资月份
                    projectGroupId: null,     //项目组
                    salaryDate: [],        
                    salaryStartDate: null, //费用开始周期
                    salaryEndDate: null,   //费用结束周期
                    attachmentUrls: [],  //附件信息
                },
                //勾选人员列表删除数组
                deleteData: [],
                formTable: {
                    personTypes: null,    //人员类型列表  
                },
                // 必填字段校验
                rules: {
                    salaryName: [{
                        required: true,
                        message: "请输入工资名称",
                        trigger: "blur"
                    }],
                    salaryTotal: [{
                        required: true,
                        message: "请输入工资总额",
                        trigger: "blur"
                    }],
                    bidId: {
                        required: true,
                        message: "请选择标段名称",
                        trigger: "change"
                    },
                    salaryMonth: {
                        required: true,
                        message: "请选择工资月份",
                        trigger: "change"
                    },
                    salaryDate: {
                        required: true,
                        message: "请选择费用周期",
                        trigger: "change"
                    },
                },
                //人员列表
                table: {
                    api: vm.$api_hw.personnelInfoList,
                    query: {
                        personTypes: null,    //人员类型列表  
                    },
                    // 是否显示分页
                    // hidePagination: true,
                    // 表格查询的条件组件
                    searchData: [{
                        type: 'multiple',
                        title: '人员类型',
                        model: 'personTypes',
                        placeholder: '请选择',
                        option: [],
                        width: '200px'
                    },],     
                    columns: [{
                            type: 'selection',
                            width: '50px',
                        },
                        {
                            title: '序号',
                            width: '50px',
                            $index: 'index',
                        },
                        {
                            title: '姓名',
                            key: 'name',
                            width: '120px',
                        }, {
                            title: '身份证号码',
                            showTooltip: true,
                            key: 'idCardNumber',
                        }, {
                            title: '人员类型',
                            showTooltip: true,
                            key: 'personTypeDes',
                            // render(h, ctx) {
                            //     return h("span", vm.personType(ctx.row.personType));
                            // }
                        }, {
                            title: '所属企业',
                            width: '140px',
                            key: 'companyId',
                        }
                    ]
                },
            };
        },
        methods: {
            //关闭
            onClose() {
                BUS.$emit(BUSEVENT.CLOSE_NAV, this.$route);
            },
            //新增按钮
            async onAdd() {
                this.personnelTableDialog = true
                let enumProperties = await this.$api_hw.public_getEnumProperties({
                    typeName: 'PersonType'
                })
                for(var i= 0; enumProperties.list.length > i; i++){
                    this.personDataType.push({
                        value: enumProperties.list[i].code,
                        label: enumProperties.list[i].des,
                    })
                }
                //await this.$search(this.table)
                let personData = await this.$api_hw.personnelInfoList({
                    currentPage: this.currentPage,
                    pageSize: this.pageSize,
                    personTypes: []
                })
                
                this.personData = await personData.data.filter(item => !this.allTableData.some(e => e.personId === item.personId))
                this.newAllTableData = this.personData.slice(0,this.pageSize)
                this.totalNum = this.newAllTableData.length  
            },
            /**
             * 人员分页
             */
            async handleCurrentChange(val) {
                console.log(val)  
                let start = (val-1) * this.pageSize
                let nextStart = start + this.pageSize
                if(this.totalNum > nextStart){
                    this.newAllTableData = this.personData
                }else{
                    let newPersonData1 = await this.$api_hw.personnelInfoList({
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                        personTypes: this.formTable.personTypes === "" ? null : [this.formTable.personTypes]
                    })
                    this.newAllTableData = newPersonData1.data
                }
            },
            //人员筛选
            async clickPersonType(){
               let newPersonData = await this.$api_hw.personnelInfoList({
                    currentPage: this.currentPage,
                    pageSize: this.pageSize,
                    personTypes: this.formTable.personTypes === "" ? null : [this.formTable.personTypes]
                })
                this.personData = await newPersonData.data.filter(item => !this.allTableData.some(e => e.personId === item.personId))
                this.newAllTableData = this.personData.slice(0,this.pageSize)
                this.totalNum = this.newAllTableData.length
            },
            //根据租户信息获取标段信息
            async getDistinctBid(val) {
                let projectTeam = await this.$api_hw.districtInfo_bidManageGet({
                    id: val[1]
                })
                this.form.projectGroupId = projectTeam.projectGroupId
            },
            //编辑工资信息
            onEdit(val) {
                //传views的url
                this.viewUrlForDialog = "/district-management/salary-management/edit-wage";
                //传参给弹窗
                this.dataForDialog = {
                    "personId": val,
                    "allTableData": this.allTableData
                };
                //显示弹窗
                this.isShowDialog = true;
            },
            async submitInternal() {
                await this.$api_hw.salary_add({
                    sanitationSalaryManageVO: {
                        salaryName: this.form.salaryName,
                        salaryTotal: this.form.salaryTotal,
                        bidId: this.form.bidId[1],
                        projectGroupId: this.form.projectGroupId,
                        salaryMonth: this.form.salaryMonth,
                        salaryStartDate: this.form.salaryDate[0],
                        salaryEndDate: this.form.salaryDate[1],
                        attachmentUrls: this.form.attachmentUrls.length !== 0 ? this.form.attachmentUrls[0].url : '',
                        details: this.allNewTableData
                    }                  
                })             
                await this.$notify({
                    title: '成功',
                    message: '新增工资成功',
                    type: 'success'
                });
                await this.onClose()
                await this.$router.push('/district-management/salary-management/salary-management')   
            },
            //提交
            onSubmit() {
                this.$refs.form.validate(async valid => {
                    if (!valid) return;
                    this.submitLoading = true;
                    try {
                        this.submitInternal();
                    } catch (e) {
                        console.log(e);
                    } finally {
                        this.submitLoading = false;
                    }
                });
            },
            // 人员类型
            personType(res){
                switch (res){
                    case 0:
                        return '项目经理'
                    case 1:
                        return '项目副经理'
                    case 2:
                        return '片区负责人'
                    case 3:
                        return '车队长'
                    case 4:
                        return '小组长'
                    case 5:
                        return '安全生产管理员'
                    case 6:
                        return '一线保洁人员'  
                    case 7:
                        return '垃圾收集工'  
                    case 8:
                        return '设备操作工'
                    case 9:
                        return '扫路车司机' 
                    case 10:
                        return '清洗车司机'
                    case 11:
                        return '垃圾收集车司机'
                    case 12:
                        return '钩臂车司机'
                    case 13:
                        return '平板车司机'
                    case 14:
                        return '公厕保洁员'   
                    case 15:
                        return '水电工' 
                    case 16:
                        return '其它'    
                }
            },
            //勾选人员列表事件
            handleSelectionChange(val) {
                // console.log(val)
                this.newTableData = val          
            },
            //弹窗点击确定
            onDetermine() {
                let arr = []
                for(let i = 0; this.newTableData.length > i; i++){
                    arr.push({
                        personId: this.newTableData[i].personId,
                        name: this.newTableData[i].name,
                        idCardNumber: this.newTableData[i].idCardNumber,
                        personTypeDes: this.newTableData[i].personTypeDes,
                        personType: this.newTableData[i].personType,
                    })                
                }
                this.allTableData = this.allTableData.concat(this.$_.cloneDeep(arr));       //深拷贝上一次填写的数据，拼接新勾选的数据
                this.allNewTableData = this.allTableData
                // console.log(this.allTableData)
                this.personnelTableDialog = false
            },
            //工资表勾选事件
            wageSelectionChange(val) {
                this.deleteData = val
            },
            //删除
            onDelete() {
                this.allNewTableData = this.allNewTableData.filter(item => !this.deleteData.some(e => e.personId === item.personId))
                this.allTableData = this.allTableData.filter(item => !this.deleteData.some(e => e.personId === item.personId))
            }
        },

        created() {
            let that = this
            BUS.$on(BUSEVENT.REFRESH_EDIT_WAGE,(function(v) {
                that.allTableData = that.$_.cloneDeep(v)
                that.allNewTableData = Object.assign([],that.allTableData) 
            }))
        },
        async mounted() { 
            this.common_getBidNameAndIdList = await this.$api_hw.common_getBidNameAndIdList({})    
        },
    };
</script>

<style lang='sass' scoped>
.content-box 
  padding-top: 20px

.form-label-base
  border-left: 2px solid $-color-primary-3
  padding-left: 8px
/deep/ .el-button--primary
    background-color: $-color-primary-3
    border-color: $-color-primary-3
/deep/.el-button--success:active 
    background: $-color-primary-3
    border-color: $-color-primary-3
/deep/.el-table thead.is-group th
    color: #2E3033
/deep/.el-button--text
    color: $-color-primary-3
/deep/.el-checkbox__input.is-checked .el-checkbox__inner
    background-color: $-color-primary-3
    border-color: $-color-primary-3
/deep/.el-checkbox__input.is-indeterminate .el-checkbox__inner
    background-color: $-color-primary-3
    border-color: $-color-primary-3
/deep/.el-button--success 
    background-color: $-color-primary-3
    border-color: $-color-primary-3
/deep/.el-checkbox__inner:hover 
    border-color: $-color-primary-3
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active
    background-color: $-color-primary-3
    color: #FFFFFF
.btn
    height: 28px
    border: 1px solid $-color-primary-3
    border-radius: 4px
    color: $-color-primary-3
</style>